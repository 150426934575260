
import studyModule from './module';
import chatModule from '../Chat/module';
import { defineComponent } from '@vue/runtime-core';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import { sendResponses } from './helpers';
import store, { setUser } from '@/store';
import { router } from './main';
import { FirebaseState } from '@/ts/state/FirebaseState';
import { UserState } from '@/ts/state/UserState';
import { firebase } from '../plugins/FirebasePlugin';

// import { connectFunctionsEmulator } from 'firebase/functions';

type tokenResult = {
    error?: true,
    customToken?: string,
    sex?: UserState['sex']
} & Partial<FirebaseState['claims']>

export const load = async () => {
    try{
        if(!firebase.app) throw new Error('Firebase app is null!');
        
        const functions = getFunctions(firebase.app, 'europe-west3');
        // connectFunctionsEmulator(functions, "localhost", 5001);

        if(!store.hasModule('study'))
            store.registerModule('study', studyModule);
            
        const { token } = router!.currentRoute.value.params;

        const result = await httpsCallable(functions, 'findToken')(token);
        const data = result.data as tokenResult;
        if(data.error)
            throw new Error('An error occured...');

        setUser({ sex: data.sex });

        await signInWithCustomToken(getAuth(), data.customToken!);

        return data;
    }catch(error){
        console.error(error);
        return { notFound: true };
    }
}

export default defineComponent({
	async beforeMount() {
        const data = await load();
        if('notFound' in data)
            return this.notFound = true;


        if(!store.hasModule('chat'))
            store.registerModule('chat', chatModule);

        const { hasCorona, filledRecently, filledToday, usedAt } = data;

        if(hasCorona && !filledRecently && !filledToday)
            return import(`@/${this.appMode}/helpers`).then(({ goToQuestionnaire }) => goToQuestionnaire());

        const route = (() => {
            if(!usedAt)
                return this.$route.meta.withAgreement ? 'agreement' : 'demographics';
            if(hasCorona && filledRecently)
                return 'filled-recently';
            if(!hasCorona || filledToday)
                return 'filled';
        })();

        sendResponses({ entered: true, goingTo: route as string });
        this.$router.push(`/token/${this.$route.params.token}/${route}`);
	},
    data: () => ({ requestSent: false, notFound: false }),
});
