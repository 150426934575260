import { namespacedToCommit, namespacedToDispatch } from "@/helpers/store";
import { ChatQuestion } from "@/ts/interfaces/Question";
import { ChatState, questionnaireMetadata } from "@/ts/state/ChatState";
import { State } from "@/ts/state/State";
import { ActionContext } from "vuex";

const initialState = () => ({
    questions: [],
    questionnaireMetadata: null,
    lastSet: 0,
    submitting: false,

    time: null, // Might not be necessary anymore
})

const mutations = {
    setQuestions(state: ChatState, questions: ChatQuestion[]){
        state.questions = questions;
    },
    setLastSet(state: ChatState, index: number){
        state.lastSet = index;
    },
    setQuestionnaireMetadata(state: ChatState, metadata: questionnaireMetadata){
        state.questionnaireMetadata = metadata;
    },
    setSubmitting(state: ChatState, value?: boolean){
        state.submitting = typeof value === 'boolean' ? value : !state.submitting;
    },
    reset(state: ChatState){
        Object.assign(state, initialState());
    },
    updateValue(state: ChatState, { index, value }: { index: number, value: ChatQuestion['val'] }){
        state.questions[index].val = value;
    }
    // assignFetched: (state, assign) => Object.assign(state, assign),
}

const toCommit = namespacedToCommit('chat');

export const setLastSet = toCommit(mutations.setLastSet);
export const setQuestionnaireMetadata = toCommit(mutations.setQuestionnaireMetadata);
export const setSubmitting = toCommit(mutations.setSubmitting);
export const reset = toCommit(mutations.reset);

const actions = {
    setQuestions({ commit }: ActionContext<ChatState, State>, questions: ChatQuestion[]){
        const firstQuestionIndex = questions.findIndex(q => q.type !== 'legend');
        questions.forEach((question, index) => {
            if(index > firstQuestionIndex && question.show)
                question.show = false;
        });

        commit('setQuestions', questions);
        if(firstQuestionIndex >= 0)
            commit('setLastSet', firstQuestionIndex);
    }
}

const toDispatch = namespacedToDispatch('chat')
export const setQuestions = toDispatch(actions.setQuestions);

export default {
    namespaced: true,
    state: initialState(),
    mutations,
    actions
}
