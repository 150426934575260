
import store from "@/store";
import { FirebaseState } from "@/ts/state/FirebaseState";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, getIdTokenResult, signOut, setPersistence, inMemoryPersistence } from 'firebase/auth';
import { App } from "vue";
import { Router } from "vue-router";

export const firebase: { app: FirebaseApp | null } = { app: null }

export const FirebasePlugin = (config: FirebaseOptions, router: Router) => ({
    install(app: App){
        app.config.globalProperties.$firebase = firebase.app = initializeApp(config);

        store.registerModule('firebase', {
            namespaced: true,
            state: {
                loaded: false,
                user: null,
                claims: {}
            } as FirebaseState,
            mutations: {
                setUser: (state, user: FirebaseState['user']) => Object.assign(state, { user, loaded: true }),
                setClaims: (state, claims: FirebaseState['claims']) => state.claims = claims
            }
        });

        const auth = getAuth();
        // setPersistence(auth, inMemoryPersistence);

        onAuthStateChanged(auth, async user => {
            store.commit('firebase/setUser', user);
            if(!user) return;

            const { claims } = await getIdTokenResult(user);
            store.commit('firebase/setClaims', claims);
            if(claims.isSubject) return;

            if(claims.isAdmin || claims.isInvestigator || claims.isViewer)
                return router.replace('/admin');

            signOut(auth);
            router.replace('/');
        });
    }
});