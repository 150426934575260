import { StudyState } from '@/ts/state/StudyState';
import { namespacedToCommit } from '@/helpers/store';

const mutations = {
    agreementInvalid: (state: StudyState, item: keyof StudyState['agreement']['valid']) => {
        state.agreement.valid[item] = false
    },
    setHasCorona: (state: StudyState, hasCorona: boolean) => {
        state.hasCorona = hasCorona;
    }
}

const toCommit = namespacedToCommit('study');

export const agreementInvalid = toCommit(mutations.agreementInvalid);
export const setHasCorona = toCommit(mutations.setHasCorona);

export default {
    namespaced: true,
    state: {
        dem: { age: null, button: 'continue' },
        hasCorona: null,
        sex: null,
        agreement: {
            share: null,
            read: false,
            agreed: false,
            valid: { share: true, read: true, agreed: true }
        },
        id: null,
        type: null
    } as StudyState,
    mutations
}
