import { setQuestions } from '@/Chat/module';
import { router } from './main';
import store from '@/store';
import { ChatQuestion, isResponse, Response } from '@/ts/interfaces/Question';
import { UserState } from '@/ts/state/UserState';
import { collection, doc, getFirestore, updateDoc, arrayUnion, FieldValue, getDocs, query, QueryConstraint } from 'firebase/firestore';
import { ref, getStorage, getBlob } from 'firebase/storage';
import { format } from 'date-fns';
import { parser } from '@/helpers';

type subject = {
    sex: UserState['sex'],
    age: number | null,
    agreedAt: number,
    agreedToShare: boolean,
    hasCorona: boolean,
    autoSaves: Response[] | FieldValue
}

type response = {
    question_id: number,
    value: string | number | (string | number | null)[],
    valueText?: string,
    tree_top: number,
    direct_parent: number
}

type rawRecord = {
    createdAt: number,
    responses: response[]
}

export type record = rawRecord & {
    createdAtFormatted: string,
    responses: { [key: string]: response },
    sex: UserState['sex'],
    hasCorona: boolean,
    agreedToShare: boolean,
    agreedAt: number,
    age: number,
    uid: string
}

export const collectionRef = (name: string) => collection(getFirestore(), name);
export const doUpdate = (col: string, updates: any) => {
    try {
        updateDoc(doc(collectionRef(col), store.state.firebase!.user!.uid), updates);
    } catch(error) {
        sendMessage(JSON.stringify({ collection: col, error: JSON.stringify(error) }), 'errors')
    }
}

export const updateSubject = async (updates: Partial<subject>) => {
    doUpdate('subjects', updates);
    sendResponses(updates);
};

export const sendResponses = async (responses: Response[] | Partial<subject> | { entered: true, goingTo: string }, key: 'subjects' | 'autoSaves' = 'autoSaves') => {
    try {
        const newItem: any = { createdAt: Date.now() };
        if(Array.isArray(responses))
            newItem.responses = responses;
        else
            Object.assign(newItem, responses);

        newItem.responses?.forEach((response: response & { tree_path?: string }) => {
            const path = response.tree_path?.split('/');
            delete response.tree_path;
            if(!path) return;

            response.tree_top = parseInt(path[0]);
            response.direct_parent = parseInt(path[path.length - 1]);
        });

        return doUpdate(key, { records: arrayUnion(newItem) });
    } catch(error: any) {
        sendMessage(JSON.stringify({ key, error: JSON.stringify(error) }), 'errors');
    }
}
export const sendMessage = async (message: string, type: 'errors' | 'comments' = 'errors') => {
    try {
        const ref = doc(collectionRef('messages'), type);
        const { uid } = store.state.firebase!.user!;
        return updateDoc(ref, { [uid]: arrayUnion({ text: message.toString(), timestamp: Date.now() }) });
    } catch(error: any) {
        console.error(error);
    }
};

export const getQuestionsFromQuestionnaires = async(questionnaires?: string[], withModifications = true): Promise<ChatQuestion[]> => {
    const { appMode } = await import(`@/appModes`);
    const { allQuestionnaires, applyModifications } = await import(`@/${appMode}/questionnaires/helpers`);
    questionnaires = questionnaires ?? allQuestionnaires as string[];

    return (await Promise.all(
        questionnaires.map(async questionnaire => {
            const module = await import(`@/${appMode}/questionnaires/${questionnaire}.json`);
            return (withModifications && applyModifications?.(questionnaire, module.default)) || module.default;
        })
    )).flat();
}

export const genericGoToQuestionnaire = async (questionnaires: string[]) => {
    const questions = await getQuestionsFromQuestionnaires(questionnaires);
    questions[0].show = true;
    setQuestions(questions);
    
    const { token } = router!.currentRoute.value.params;
    router!.push(`/token/${token}/questionnaire`);
}

export const getRole = () => {
    const { isAdmin, isInvestigator, isViewer } = store.state.firebase!.claims;
    return isAdmin ? 'admin' : isInvestigator ? 'investigator' : isViewer ? 'viewer' : null;
}

export const makeCompoundId = (obj: ChatQuestion | Response) => {
    let splitPath = obj.tree_path?.split('/');
    if(splitPath && splitPath.length > 2)
        splitPath = [splitPath[0], splitPath[splitPath.length - 1]]
    if('tree_top' in obj && obj.tree_top){
        splitPath = [obj.tree_top.toString()]
        if(obj.tree_top !== obj.direct_parent)
            splitPath.push(obj.direct_parent!.toString())
    }
        
    const treePath = splitPath ? splitPath.join('/') + '/' : '';

    return `${treePath}${!isResponse(obj) ? obj.id : obj.question_id}`
};

export const getCachedRecords = async (): Promise<record[] | { error: any }> => {
    const dataRef = ref(getStorage(), 'data.json');
    let cachedRecords: record[] | null = null;

    try{
        const fetched = await getBlob(dataRef);
        cachedRecords = JSON.parse(await fetched.text());
    }catch(error: any){
        if(!error.code.includes('object-not-found')){
            console.error(error);
            return { error };
        }
    }

    return cachedRecords!;
}

type options = {
    merge82?: true,
    questionnaires?: string[],
    getQuestionnaires?: () => string[]
};
type questionsObj = { [key: string]: ChatQuestion };
export const getAllRecords = async (options = {} as options) => {
    const questionsObj: questionsObj = {};
    const questions = await getQuestionsFromQuestionnaires(options.questionnaires ?? options.getQuestionnaires?.(), false);
    questions.forEach(question => {
        const id = makeCompoundId(question);  
        Object.assign(question, { compoundId: id, text: parser(question.text) });
        questionsObj[id] = question;
    });

    const prepareRecordsWithOptions = prepareRecords({ ...options, questionsObj, questions });
    const { getRecords } = await import(`@/CoronaStudy/helpers`);
    const allRecords = await getRecords(prepareRecordsWithOptions);
    if('error' in allRecords) throw allRecords.error;

    return { records: allRecords.sort((a, b) => parseInt(a.uid) - parseInt(b.uid)), questions };
}

const prepareRecords = (options: options & { questionsObj: questionsObj, questions: ChatQuestion[] }) => async (...filters: QueryConstraint[]) => {
    const docs = await getDocs(query(collectionRef('subjects'), ...filters));

    const parseValue = (val: any): any => {
        if(typeof val !== 'string')
            return !isNaN(val) ? parseInt(val) : null;
        if(!val.startsWith('[')){
            const parsed = parseInt(val);
            return isNaN(parsed) || parsed.toString().length !== val.length ? val : parsed;
        }
        
        let replaced = null;
        while(replaced !== val){
            if(replaced !== null) val = replaced;
            // eslint-disable-next-line
            replaced = val.replace(/([^,\[])(")([^,\]])/g, '$1″$3');
        }

        return JSON.parse(replaced);
    }

    const allRecords: record[] = [];
    docs.forEach(doc => {
        if(doc.id === 'count' || doc.id.startsWith('t')) return;
        
        const data = doc.data();
        const { agreedToShare, age, records, agreedAt } = data;
        const sex = data.sex as UserState['sex'];

        records.forEach((record: rawRecord) => {
            Object.assign(record, {
                createdAtFormatted: format(new Date(record.createdAt), 'd/M/Y @ H:mm'),
                sex: sex ? { male: 'זכר', female: 'נקבה', other: 'אחר' }[sex] : '',
                agreedToShare: agreedToShare ? 'כן' : 'לא',
                hasCorona: data.hasCorona ? 'כן' : 'לא',
                responses: Object.fromEntries(record.responses.map(response => {
                    const id = makeCompoundId(response);
                    const question = options.questionsObj[id] ?? options.questions.find(q => q.id === response.question_id);

                    const hasOptions = question.options && Object.keys(question.options).length;
                    const value = parseValue(response.value);

                    const getText = (v: string | number) => question.options![v as number] ?? v;

                    const valueText = !Array.isArray(value)
                        ? (hasOptions ? parser(getText(value)) : value)
                        : value.map((item: string | number | null, index) => {
                                if(options.merge82 && question.id == 20 && item == 0){
                                    // Question 20 has 2 options: 0 => yes, 1 => no.
                                    // Options from 82 are appended to this, so that 82[0] becomes 20[2], 82[1] becomes 20[3], etc.
                                    const res82 = record.responses.find(r => r.question_id == 82);
                                    if(res82){
                                        const numVal = parseInt(res82.value as string);
                                        if(!isNaN(numVal)) item = (numVal + 2).toString();
                                    }
                                }

                                const isOther = typeof item === 'string' && item.length && isNaN(parseInt(item));
                                if(item === 'na' || isOther){
                                    value[index] = !isOther ? '100' : '101';
                                    return item === 'na' ? 'אף אחד מהנ"ל' : item;
                                }

                                return parser(getText(item as number) ?? item);
                            }).join(', ')

                    Object.assign(response, { value, valueText });
                
                    return [id, response];
                })),
                agreedAt,
                age,
                uid: doc.id
            });

            // Only people who actually have records will get pushed here, so that this behaves as a sort of a filter
            allRecords.push(record as record);
        });
    });

    return allRecords;
}

export const pdfPath = (uid = store.state.firebase!.user!.uid) => `https://europe-west3-corona-study-334515.cloudfunctions.net/agreement?uid=${uid}`;